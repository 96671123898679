import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getDoctorManagementDetails } from '../../services';
interface DoctorState {
  list: any; // Replace `any` with the actual type of your doctor list items
  loading: boolean;
  error: string | null;
}

const initialState: DoctorState = {
  list: [],
  loading: false,
  error: null
};

// Create an async thunk for fetching doctor details
export const fetchDoctorList = createAsyncThunk('doctor/fetchDoctorList', async () => {
  const response = await getDoctorManagementDetails();

  return response;
});

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDoctorList.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(fetchDoctorList.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch doctor details';
        state.loading = false;
      });
  }
});

export default doctorSlice.reducer;
