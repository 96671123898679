// selectors.ts
import { createSelector } from 'reselect';
import { DoctorState, RootState } from 'data'; // Update with your actual import paths

// Base selector to get the doctor state
const doctorSelector = (state: RootState) => state.doctor;

/**
 * Helper function to create memoized selectors for different fields
 * @param field - The specific field of the doctor state to select
 * @returns A memoized selector for the specified field
 */
const createdoctorSelector = <T extends keyof DoctorState>(field: T) =>
  createSelector([doctorSelector], (doctorState): DoctorState[T] => doctorState[field]);

// Memoized selectors for various fields in the doctor state
export const selectDoctorList = createdoctorSelector('list');
export const selectDoctorLoading = createdoctorSelector('loading');
export const selectDoctorError = createdoctorSelector('error');
