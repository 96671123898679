import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAdditionalQualifications,
  fetchSpecialisations,
  fetchTreatments,
  fetchServiceQualifications
} from './thunk';

// Define the initial state
const initialState = {
  list: [],
  additionalQualifications: [],
  treatments: [],
  loading: false,
  error: null as string | null,
  serviceQualifications: []
};

// Create the slice
const specialistQualificationsSlice = createSlice({
  name: 'specialistQualifications',
  initialState,
  reducers: {
    getSpecialisations: (state, action) => {
      state.list = action.payload;
    },
    setAdditionalQualifications: (state, action) => {
      state.additionalQualifications = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialisations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpecialisations.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchSpecialisations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchAdditionalQualifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdditionalQualifications.fulfilled, (state, action) => {
        state.loading = false;
        state.additionalQualifications = action.payload;
      })
      .addCase(fetchAdditionalQualifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchTreatments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTreatments.fulfilled, (state, action) => {
        state.loading = false;
        state.treatments = action.payload;
      })
      .addCase(fetchTreatments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchServiceQualifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceQualifications.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceQualifications = action.payload;
      })
      .addCase(fetchServiceQualifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const { getSpecialisations, setAdditionalQualifications } =
  specialistQualificationsSlice.actions;

export default specialistQualificationsSlice.reducer;
