import { createSelector } from 'reselect';
import { RootState, SpecialistQualificationState } from 'data';

// Define selectSpecialistQualifications  selectors
const specialistQualificationSelector = (state: RootState) => state.specialistQualifications;
/**
 * Helper function to create memoized selectors for different fields
 * @param field - The specific field of the specialist qualifications state to select
 * @returns A memoized selector for the specified field
 */

const createSpecialistQualifications = <T extends keyof SpecialistQualificationState>(field: T) =>
  createSelector(
    [specialistQualificationSelector],
    (specialistQualificationState): SpecialistQualificationState[T] =>
      specialistQualificationState[field]
  );

// Memoized selectors for various fields in the specialist qualifications state
export const selectSpecialistQualifications = createSpecialistQualifications('list');

export const selectAdditionalQualifications = createSpecialistQualifications(
  'additionalQualifications'
);
export const selectTreatments = createSpecialistQualifications('treatments');
export const selectSpecialisationLoading = createSpecialistQualifications('loading');
export const selectSpecialisationError = createSpecialistQualifications('error');
export const selectSerivceQualifications = createSpecialistQualifications('serviceQualifications');
