import { API, axiosInstance, handleResponse } from './helper';

interface InsertServicePayload {
  service: string;
  data: {
    qualificationId: string | undefined;
  }[];
}
interface insertAddQualificationsPayload {
  qualification: string;
  type: string;
}

interface UpdateServicePayload {
  serviceId: string;
  data: { serviceId: string; qualificationId: string | undefined }[];
}

interface deleteServiceQualificationPayload {
  service: string;
}
interface deleteQualificationPayload {
  specialityId: string;
}

interface UpdateSpecialityPayload {
  qualification: string | null;
  focusQualifications: string[];
  specialityId: string | null;
}

interface insertQualificationsPayload {
  qualification: string | null;
  focusQualifications: string[];
}

const getSpecialisationData = () =>
  handleResponse(axiosInstance.get(API.getSpecialistQualificationList));

const getServiceQualifications = () =>
  handleResponse(axiosInstance.get(API.getTreatmentQualifications));

const getAdditionalQualificationData = () =>
  handleResponse(axiosInstance.get(API.getAdditionalQualifications));

const getTreatmentServices = () => handleResponse(axiosInstance.get(API.getService));

const insertFocusQualifications = (insert_qualifications_payload: insertQualificationsPayload) =>
  handleResponse(axiosInstance.post(API.insertFocusQualifications, insert_qualifications_payload));

const updateSpeciality = (update_speciality_payload: UpdateSpecialityPayload) =>
  handleResponse(axiosInstance.post(API.updateSpeciality, update_speciality_payload));

const deleteQualification = (delete_qualification_payload: deleteQualificationPayload) => {
  return handleResponse(
    axiosInstance.delete(API.deleteQualifications, {
      data: delete_qualification_payload
    })
  );
};

const deleteServiceAndQualifications = (
  delete_service_qualification_payload: deleteServiceQualificationPayload
) => {
  return handleResponse(
    axiosInstance.delete(API.deleteBillableServices, {
      data: delete_service_qualification_payload
    })
  );
};

const updateService = (update_service_payload: UpdateServicePayload) =>
  handleResponse(axiosInstance.post(API.updateServiceQualifications, update_service_payload));

const insertAdditionalQualifiacation = (
  insert_add_qualifications_payload: insertAddQualificationsPayload
) =>
  handleResponse(
    axiosInstance.post(API.insertAdditionalQualifications, insert_add_qualifications_payload)
  );

const insertService = (insert_service_payload: InsertServicePayload) =>
  handleResponse(axiosInstance.post(API.insertTreatmentService, insert_service_payload));

export {
  getSpecialisationData,
  getAdditionalQualificationData,
  getTreatmentServices,
  insertFocusQualifications,
  updateSpeciality,
  deleteQualification,
  insertAdditionalQualifiacation,
  insertService,
  getServiceQualifications,
  deleteServiceAndQualifications,
  updateService
};
