import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  getSpecialisationData,
  getAdditionalQualificationData,
  getTreatmentServices,
  getServiceQualifications
} from '../../services';

// Create async thunk for fetching specialisation data
export const fetchSpecialisations = createAsyncThunk(
  'specialistQualifications/fetchSpecialisations',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getSpecialisationData();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          // Network error
          return rejectWithValue('Network error');
        } else if (error.response.status >= 500) {
          // Server error
          return rejectWithValue('Server error');
        } else if (error.response.status === 404) {
          // Endpoint not found
          return rejectWithValue('Not found error');
        } else {
          // Other errors
          return rejectWithValue(error.message);
        }
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

// Create async thunk for fetching additional qualifications data
export const fetchAdditionalQualifications = createAsyncThunk(
  'specialistQualifications/fetchAdditionalQualifications',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getAdditionalQualificationData();
      console.log(result, 'add data');
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          // Network error
          return rejectWithValue('Network error');
        } else if (error.response.status >= 500) {
          // Server error
          return rejectWithValue('Server error');
        } else if (error.response.status === 404) {
          // Endpoint not found
          return rejectWithValue('Not found error');
        } else {
          // Other errors
          return rejectWithValue(error.message);
        }
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

// Create async thunk for fetching treatments
export const fetchTreatments = createAsyncThunk(
  'specialistQualifications/fetchTreatments',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getTreatmentServices();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          // Network error
          return rejectWithValue('Network error');
        } else if (error.response.status >= 500) {
          // Server error
          return rejectWithValue('Server error');
        } else if (error.response.status === 404) {
          // Endpoint not found
          return rejectWithValue('Not found error');
        } else {
          // Other errors
          return rejectWithValue(error.message);
        }
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);

// Create async thunk for fetching service qualifications
export const fetchServiceQualifications = createAsyncThunk(
  'specialistQualifications/fetchServiceQualifications',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getServiceQualifications();
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (!error.response) {
          // Network error
          return rejectWithValue('Network error');
        } else if (error.response.status >= 500) {
          // Server error
          return rejectWithValue('Server error');
        } else if (error.response.status === 404) {
          // Endpoint not found
          return rejectWithValue('Not found error');
        } else {
          // Other errors
          return rejectWithValue(error.message);
        }
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);
