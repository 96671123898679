import { createSelector } from 'reselect';
import { RootState, DashboardState } from 'data';

// Define select dashboards  selectors
const dashboardSelector = (state: RootState) => state.dashboard;
/**
 * Helper function to create memoized selectors for different fields
 * @param field - The specific field of the dashboard  state to select
 * @returns A memoized selector for the specified field
 */

const createDashboardSelector = <T extends keyof DashboardState>(field: T) =>
  createSelector([dashboardSelector], (dashboardState): DashboardState[T] => dashboardState[field]);

// Memoized selectors for various fields in the dashboard  state
export const selectDashboardList = createDashboardSelector('list');
export const selectDashboardLoading = createDashboardSelector('loading');
export const selectDashboardError = createDashboardSelector('error');
