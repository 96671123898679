import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logoImage from '../../assets/images/logoImage.svg';
import {
  IconDashboard,
  IconStethoscope,
  IconBuildingHospital,
  IconBrandApplePodcast,
  IconFirstAidKit,
  IconLogout2,
  IconSettingsHeart,
  IconCircleArrowLeftFilled
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import colors from '../../constants/colors';
import routes from '../../config/routes.json'; // Import the routes JSON file
import './index.css';

const iconMap: { [key: string]: React.ElementType } = {
  IconDashboard,
  IconStethoscope,
  IconBuildingHospital,
  IconBrandApplePodcast,
  IconFirstAidKit,
  IconSettingsHeart
};

const SideNavbar = () => {
  const [selected, setSelected] = useState('dashboard');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleSelect = (section: string, path: string) => {
    setSelected(section);
    navigate(path);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = routes.find((item) => item.path === currentPath);
    if (currentItem) {
      setSelected(currentItem.name);
    }
  }, [location]);

  return (
    <div className="side-nav">
      <div className="upper-navbar">
        <div className="navbar-image">
          <img src={logoImage} alt="not available" className="logo-image" />
        </div>
        <div className="toggle-icon">
          <IconCircleArrowLeftFilled size={30} color={colors.blue} />
        </div>
        <div className="navigation-buttons">
          {routes.map((item) => {
            const IconComponent = iconMap[item.icon];
            return (
              <div
                key={item.name}
                className={`button-row ${selected === item.name ? 'selected' : ''}`}
                onClick={() => {
                  if (Array.isArray(item.path)) {
                    handleSelect(item.name, item.path[0]);
                  } else {
                    handleSelect(item.name, item.path);
                  }
                }}
              >
                <div className="button-icon">
                  <IconComponent size={20} color={colors.iconColor} />
                </div>
                <div className="dashboard-button">
                  <span>{t(item.name)}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="lower-navbar">
        <div className="add-doctors-button-wrapper">
          <button className="add-doctors-button">{t('addDoctors')}</button>
        </div>
        <div className="divider"></div>
        <div className="logout-button-wrapper">
          <button className="logout-button">
            <IconLogout2 size={20} color="#3F3F3F" /> {t('logout')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
