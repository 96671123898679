import axios, { AxiosResponse } from 'axios';

export const BASE_URL = 'https://test.hasura.spicter.io/api/rest';

export const HASURA_ADMIN_SECRET = process.env.REACT_APP_HASURA_ADMIN_SECRET;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-hasura-admin-secret': HASURA_ADMIN_SECRET
  }
});

const handleResponse = async <T>(request: Promise<AxiosResponse<T>>): Promise<T> => {
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error; // re-throw to handle it in the calling function if needed
  }
};

const getDashboardData = () => handleResponse(axiosInstance.get('/get-dashboard-data'));

export { getDashboardData };
