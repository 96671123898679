export const API_ROUTES = {
  getAdditionalQualifications: 'get-additional-qualifications',
  getService: 'get-services',
  insertFocusQualifications: 'insert-focus-qualifications',
  updateSpeciality: 'update-speciality',
  insertQualifications: 'insert-qualifications',
  getSpecialistQualificationList: 'get-specialist-qualification-list',
  getDoctorManagementDetails: 'get-doctor-management-details',
  deleteQualifications: 'delete-qualifications',
  insertAdditionalQualifications: 'insert-additional-qualifications',
  insertTreatmentService: 'insert-billable-services',
  getTreatmentQualifications: 'get-treatment-qualifications',
  deleteBillableServices: 'delete-billable-services',
  updateServiceQualifications: 'update-service-qualifications'
};
