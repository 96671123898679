import axios, { AxiosResponse } from 'axios';
import { BASE_URL, HASURA_ADMIN_SECRET } from './dashboard';
import { API_ROUTES as API } from '../constants/apiRoutes';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-hasura-admin-secret': HASURA_ADMIN_SECRET
  }
});

const handleResponse = async <T>(request: Promise<AxiosResponse<T>>): Promise<T> => {
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error; // re-throw to handle it in the calling function if needed
  }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const printFormattedJSON = (jsonObject: any) => {
  return JSON.stringify(jsonObject, null, 2);
};

export { axiosInstance, handleResponse, API, printFormattedJSON };
