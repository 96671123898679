// src/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './locale/de.json';
import translationEN from './locale/en.json';

// the translations
const resources = {
  de: {
    translation: translationDE
  },

  en: {
    translation: translationEN
  }
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    resources,
    lng: 'en', // language to use
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
